<template>
    <div class="box" id="ygh-content-box">
        <div class="big">
            <div class="box-btns">
                <a-button
                    :type="btck == i ? 'primary' : ''"
                    icon="file-sync"
                    class="btns"
                    v-for="(o, i) in btna"
                    :key="i"
                    @click="change_status(i)"
                    >{{ o }}</a-button
                >
                <!-- <a-button @click="translateToRe">sss</a-button> -->
            </div>

            <div>
                <a-card :bordered="false">
                    <s-table
                        ref="table"
                        :columns="columns"
                        :data="loadData"
                        :alert="false"
                        :rowKey="(record) => record.id"
                    >
                        <span slot="operator">
                            <div style="display: flex">
                                <div class="flex" style="margin: 10px 0 0">
                                    <div>
                                        <a-input-search
                                            placeholder="请输入关键字"
                                            @search="onSearch"
                                            class="search"
                                            v-model="queryParam.name"
                                        >
                                            <a-button slot="enterButton" icon="search"> </a-button>
                                        </a-input-search>
                                    </div>
                                    <!-- <div v-if="btck == 0"><a-button type="primary"> 发起审批 </a-button></div> -->
                                </div>
                            </div>
                        </span>
                        <span slot="draftId" slot-scope="text, record">
                            <div v-for="(o, i) in record.draftId" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData
                                        :type="o.type || 'userName'"
                                        :openid="o.departmentId || o.userId"
                                        :corpid="o.corpId"
                                    >
                                    </OpenData>
                                </a-tag>
                            </div>
                            <!-- <OpenData type="userName" :openid="record.draftId" :corpid="record.corpId"></OpenData> -->
                        </span>
                        <span slot="documentType" slot-scope="text">
                            <span v-if="text == +1">收文登记</span>
                            <span v-else-if="text == +2">快速发文</span>
                            <span v-else-if="text == +3">拟稿发文</span>
                        </span>
                        <span slot="fileType" slot-scope="text">
                            {{ Filter(flow_file_type, text) }}
                        </span>
                        <span slot="createTime" slot-scope="text">
                            {{ text && text.substr(0, 10) }}
                        </span>
                        <span slot="acceptTime" slot-scope="text">
                            {{ text && text.substr(0, 10) }}
                        </span>
                        <span slot="urgencyDegree" slot-scope="text" :style="flowUrgencyDegree(text)">
                            {{ Filter(flow_urgency_degree, text) }}
                        </span>
                        <span slot="secrecyDegree" slot-scope="text" :style="flowSecrecyDegree(text)">
                            {{ Filter(flow_secrecy_degree, text) }}
                        </span>
                        <span slot="acceptStatus" slot-scope="text" :class="text != 3 ? 'fred' : ''">
                            {{ foramtAcceptStatus(text) }}
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a class="bts" v-if="record.acceptStatus == 0" @click="acceptBill(record)">签收</a>
                            <!-- <a class="bts" v-if="record.approvalStatus == 1" @click="senpi(record)">审批</a>
                <a class="bts" v-if="record.approvalStatus == 2">接收</a> -->
                            <a class="bts" @click="see(record)">查看</a>
                        </span>
                    </s-table>
                </a-card>
            </div>
        </div>
    </div>
</template>
  
<script>
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { documentBillIndexPage } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
const defaultColumns = [
    {
        title: '处理笺名称',
        dataIndex: 'name',
        ellipsis: true,
    },

    {
        title: '来文/发文字号',
        dataIndex: 'documentNumber',
        ellipsis: true,
    },

    {
        title: '类型',
        dataIndex: 'documentType',
        // width: 100,
        scopedSlots: { customRender: 'documentType' },
    },
    {
        title: '紧急程度',
        dataIndex: 'urgencyDegree',
        // width: 100,
        scopedSlots: { customRender: 'urgencyDegree' },
    },
    {
        title: '密级程度',
        dataIndex: 'secrecyDegree',
        // width: 150,
        scopedSlots: { customRender: 'secrecyDegree' },
    },

    {
        title: '拟稿单位/人',
        dataIndex: 'draftId',
        // width: 120,
        scopedSlots: { customRender: 'draftId' },
    },

    {
        title: '拟稿日期',
        dataIndex: 'createTime',
        // width: 150,
        scopedSlots: { customRender: 'createTime' },
    },
    {
        title: '收文日期',
        dataIndex: 'acceptTime',
        // width: 150,
        scopedSlots: { customRender: 'acceptTime' },
    },
    {
        title: '状态',
        dataIndex: 'acceptStatus',
        // width: 120,
        scopedSlots: { customRender: 'acceptStatus' },
    },
    {
        title: '操作',
        // width: 240,
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    },
]
export default {
    name: 'list',
    mixins: [stateColor],
    components: {
        STable,
        OpenData,
    },
    computed: {
        columns() {
            let arr = defaultColumns.slice()
            if (this.queryParam.approvalTab === 1)
                arr = arr.filter((j) => !['approvalStatus', 'acceptTime'].includes(j.dataIndex))
            return arr
        },
    },
    watch: {
        '$route.query.index': {
            handler() {
                this.changeTabStatus(Number(this.$route.query.index) || 0)
            },
            immediate: true,
        },
    },
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['全部', '待收公文', '已收公文'],
            btck: 0,
            // 查询参数
            queryParam: { approvalTab: 1 },
            // 表头
            // columns: defaultColumns,
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                return documentBillIndexPage(Object.assign(parameter, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66, res)
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
            selectedCorpGroupDepartmentList: '',
            selectedDepartmentList: '',
        }
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66)
    },
    methods: {
        Filter(arr, s) {
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        translateToRe() {
            // this.$router.push({ path: '/fileStreamDocumentManage', replace: true })
            this.$router.push({ name: 'mobileHome' })
        },
        foramtAcceptStatus(text) {
            switch (Number(text)) {
                case 0:
                    return '待接收'
                case 1:
                    return '已接收'
                default:
                    return ''
            }
        },
        acceptBill({ id, documentType }) {
            let url = '/fileStream_Approval_process'
            if (documentType === 2) {
                url = '/fileStreamDocumentSend'
            }
            this.noticeUpdate(id)
            this.$router.push({ path: url, query: { id, type: documentType, todo: 'accept' } })
        },
        /**
         * 获取字典数据
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
            })
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        changeTabStatus(i) {
            this.btck = i
            this.queryParam.approvalTab = i
            this.$refs.table && this.$refs.table.refresh(true)
        },
        change_status(i) {
            if (this.btck === i) return
            this.$router.push({
                query: {
                    index: i,
                },
            })
        },
        edit(record) {
            this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        senpi(record) {
            this.$router.push({ path: '/fileStream_Approval_Manage', query: { id: record.id } })
        },
        see(record) {
            let url = '/fileStream_Approval_process'
            if (record.documentType == 2) {
                url = '/fileStreamDocumentSend'
            }
            this.$router.push({ path: url, query: { id: record.id, type: record.documentType } })
        },
        // 去掉未通知提示
        noticeUpdate(id) {
            this.$store.dispatch('msg/noticeRefreshUpdate', { id }).then((res) => {
                if (res.code === 200) {
                    this.$store.dispatch('msg/unReadNums')
                }
            })
        },
    },
}
</script>
  
<style lang="less" scoped>
.box {
    // width: 1088px;
    background: #ffffff;
    border-radius: 4px;
    margin: 0 auto;

    padding: 33px 39px;

    .big {
        button {
            font-size: 16px;
        }
    }

    .flex {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px 0;
    }

    .search {
        width: 300px;
        border-radius: 4px;
    }
}

::v-deep .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
}

::v-deep .ant-card-body {
    padding: 0;
}

.btns {
    margin-right: 17px;
}

.fred {
    color: #f56c6c;
}

.bts {
    margin-right: 10px;
}

.flex0 {
    display: flex;
    align-items: center;
}

.my-item {
    display: inline-block;

    .tags {
        margin-bottom: 1px;
    }
}
</style>
  